html {
  height: 100%;
}

:root {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #696cff;
  --bs-pink: #e83e8c;
  --bs-red: #ff3e1d;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffab00;
  --bs-green: #71dd37;
  --bs-teal: #20c997;
  --bs-cyan: #03c3ec;
  --bs-white: #fff;
  --bs-gray: rgba(67, 89, 113, 0.6);
  --bs-gray-dark: rgba(67, 89, 113, 0.8);
  --bs-gray-25: rgba(67, 89, 113, 0.025);
  --bs-gray-50: rgba(67, 89, 113, 0.05);
  --bs-primary: #696cff;
  --bs-secondary: #8592a3;
  --bs-success: #71dd37;
  --bs-info: #03c3ec;
  --bs-warning: #ffab00;
  --bs-danger: #ff3e1d;
  --bs-light: #fcfdfd;
  --bs-dark: #233446;
  --bs-gray: rgba(67, 89, 113, 0.1);
  --bs-primary-rgb: 105, 108, 255;
  --bs-secondary-rgb: 133, 146, 163;
  --bs-success-rgb: 113, 221, 55;
  --bs-info-rgb: 3, 195, 236;
  --bs-warning-rgb: 255, 171, 0;
  --bs-danger-rgb: 255, 62, 29;
  --bs-light-rgb: 252, 253, 253;
  --bs-dark-rgb: 35, 52, 70;
  --bs-gray-rgb: 67, 89, 113;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 67, 89, 113;
  --bs-body-color-rgb: 105, 122, 141;
  --bs-body-bg-rgb: 245, 245, 249;
  --bs-font-sans-serif: "Public Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-root-font-size: 16px;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.53;
  --bs-body-bg: #f5f5f9;
  --bs-card-color: #697a8d;
  --bs-card-body-color: #566a7f;
  --bs-table-color: #697a8d;
}

body {
  margin: 0;
  height: 100%;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(67, 89, 113, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  font-size: 12px;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.company-selector.form-control:focus {
  border-color: rgba(100, 100, 100, 1) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.fileDropZoneContainer {
  text-align: center;
  padding: 20px;
  border: 1px lightgrey dashed;
  width: 100%;
  margin: auto;
}

div.legend-rounded-square {
  height: 10px;
  width: 10px;
  border-radius: 3px;
  margin-right: 10px;
}

/* Dashboard */

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.625rem);
  padding-left: var(--bs-gutter-x, 1.625rem);
  margin-right: auto;
  margin-left: auto;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.container-p-y:not([class^="pt-"]):not([class*=" pt-"]) {
  padding-top: 1.625rem !important;
}
.container-p-y:not([class^="pb-"]):not([class*=" pb-"]) {
  padding-bottom: 1.625rem !important;
}

.h-100 {
  height: 100% !important;
}

.card-body {
  color: var(--bs-card-body-color) !important;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

.rounded {
  border-radius: 0.375rem !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.avatar {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  cursor: pointer;
}
